import React from 'react';
import { Link } from 'gatsby';
import FooterSection from './footerSection';
import FacebookFooterIcon from '../assets/social-footer-facebook.svg';
import InstagramFooterIcon from '../assets/social-footer-instagram.svg';
import TwitterFooterIcon from '../assets/social-footer-twitter.svg';
import { Box, Flex, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

const StyledFooterNavLink = styled(Link)(
    ({ theme }) => `
        border-bottom: 1px dashed #4f4f4f;
        color: ${theme.color.footerText};
        display: block;
        font-size: 12px;
        font-weight: normal;
        height: 30px
        line-height: 0;
        margin: 15px 0;
        padding-bottom: 8px;
  
        &:hover {
            -moz-transition:color .2s ease-in;
            -o-transition:color .2s ease-in;
            -webkit-transition:color .2s ease-in;
            color: ${theme.color.brand};
        }
    `
);

const StyledFooterHeader = styled.p(
    ({ theme }) => `
        border-bottom: 4px solid ${theme.color.brand};
        color: white;
        display: block;
        font-size: 18px;
        font-weight: semi-bold;
        padding-bottom: 5px;
        margin-bottom: 20px;
    `
);

const StyledFooterMiddleHeader = styled(StyledFooterHeader)`
    display: block;
    font-size: 22px;
    font-weight: bold;
`;

const StyledSocialFlex = styled(Flex)`
    margin-top: 20px;

    svg {
        margin-right: 15px;
    }
`;

const Footer = () => {
    return (
        <FooterSection>
            <Flex justifyContent="space-around" direction={['column', 'row']}>
                <Box textAlign={['center', 'left']} w={['100%', '200px']} mt="30px">
                    <StyledFooterHeader>Quick Links</StyledFooterHeader>
                    <StyledFooterNavLink to="/">&gt; HOME</StyledFooterNavLink>
                    <StyledFooterNavLink to="/services">&gt; SERVICES</StyledFooterNavLink>
                    <StyledFooterNavLink to="/claim">&gt; SCHEDULE CLAIM</StyledFooterNavLink>
                    <StyledFooterNavLink to="/contact">&gt; CONTACT US</StyledFooterNavLink>
                </Box>
                <Box w={['100%', '400px']} textAlign="center" mt="30px">
                    <StyledFooterMiddleHeader>Our Mission</StyledFooterMiddleHeader>
                    <Text color="white" textAlign="center" m={['0 20px', '0']}>
                        Our mission is to provide our customers and their clients with a group of
                        men and women that can provide the best possible vertical services with
                        integrity, honor, and accuracy.
                    </Text>
                    <Box color="white" w="100%" textAlign="center">
                        <Text fontSize="12px" mt="60px">
                            © {new Date().getFullYear()} Vertical Link Services, LLC.
                        </Text>
                    </Box>
                </Box>
                <Box display={['none', 'block']} w="200px" mt="30px">
                    <StyledFooterHeader>Follow Us</StyledFooterHeader>
                    <StyledSocialFlex>
                        <Link href="#">
                            <FacebookFooterIcon />
                        </Link>
                        <Link href="#">
                            <InstagramFooterIcon />
                        </Link>
                        <Link href="#">
                            <TwitterFooterIcon />
                        </Link>
                    </StyledSocialFlex>
                </Box>
            </Flex>
        </FooterSection>
    );
};

export default Footer;
