import React from 'react';
import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

const AngledFooterBox = styled(Box)(
    ({ theme }) => `
    position: relative;
    display: block;
    padding: 20px 50px 20px 0;
    overflow: hidden;
    color: ${theme.color.white};
    width: 100%;
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        height: 100%;
        background-color: ${theme.color.footer};
        -webkit-transform-origin: 0 100%;
        -ms-transform-origin: 0 100%;
        transform-origin: 0 100%;
        -webkit-transform: skew(45deg);
        -ms-transform: skew(45deg);
        transform: skew(45deg);
        z-index: -1;
    }
`
);

const StyledFlexContainer = styled(Box)(
    ({ theme }) => `
    background-color: ${theme.color.footer};
    height: 100%;
    width: 100%;
`
);

const FooterSection = ({ children }) => {
    return (
        <Box w="100%" mt="60px">
            <AngledFooterBox />
            <StyledFlexContainer>{children}</StyledFlexContainer>
        </Box>
    );
};

export default FooterSection;
