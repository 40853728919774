import { theme } from '@chakra-ui/core';

const breakpoints = ['768px', '1200px', '1900px'];

const customColors = {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    brand: '#ffca10',
    footer: '#231F20',
    footerText: '#878787'
};

const customTheme = Object.assign({}, theme, {
    color: {
        ...theme.colors,
        ...customColors,
    },
    breakpoints
});

export default customTheme;
