import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { Burger, Menu } from './nav';
import { useOnClickOutside } from '../hooks';
import VLSLogo from '../assets/vls-logo.svg';
import FacebookIconSmall from '../assets/facebook-icon-small.svg';
import InstagramIconSmall from '../assets/instagram-icon-small.svg';
import TwitterIconSmall from '../assets/twitter-icon-small.svg';
import PhoneIconSmall from '../assets/phone-icon-small.svg';
import { Box, Flex, Link as ChakraLink, Text } from '@chakra-ui/core';
import FocusLock from 'react-focus-lock';
import styled from '@emotion/styled';

const AngledInfoFlex = styled(Flex)(
    ({ theme }) => `
    position: relative;
    display: inline-block;
    padding: 8px 20px 8px 60px;
    overflow: hidden;
    color: ${theme.color.white};
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${theme.color.black};
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(45deg);
        -ms-transform: skew(45deg);
        transform: skew(45deg);
        z-index: -1;
    }
    
    svg {
        margin-right: 15px;
        
        &:last-of-type {
            margin-right: 10px;
        }
    }
    
`
);

const StyledNavLink = styled(Link)(
    ({ theme }) => `
    color: black;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 30px
    line-height: 0;
    margin: 10px 19px;
    padding-bottom: 15px;
  
        &:hover {
            -moz-transition:color .2s ease-in;
            -o-transition:color .2s ease-in;
            -webkit-transition:color .2s ease-in;
            color: ${theme.color.brand};
        }
    `
);

const StyledVLSLogo = styled(VLSLogo)`
    width: 100%;
`;

const NavLink = (props) => (
    <StyledNavLink
        {...props}
        getProps={({ isCurrent }) => {
            return {
                style: {
                    borderBottom: isCurrent ? `5px solid #ffca10` : 'none',
                    backgroundColor: isCurrent ? 'white' : 'transparent',
                },
            };
        }}
    />
);

const Header = () => {
    const [open, setOpen] = useState(false);
    const node = useRef();
    const menuId = 'main-menu';

    useOnClickOutside(node, () => setOpen(false));

    return (
        <>
            <Flex justifyContent={['center', 'space-between']} mb="30px">
                <Box m={['30px auto', '30px auto 0 auto', '12px 0 0 100px']} w="300px">
                    <Link to="/">
                        <StyledVLSLogo />
                    </Link>
                </Box>
                <Box display={['none', 'none', 'block']} textAlign="right" w="100%" ml="30px">
                    <AngledInfoFlex justifyContent="flex-end" h="40px" w="50%">
                        <Flex justifyContent="space-between" alignItems="center">
                            <Flex justifyContent="flex-start" alignItems="center">
                                <Text mr="10px">Follow Us:</Text>
                                <ChakraLink href="#">
                                    <FacebookIconSmall />
                                </ChakraLink>
                                <ChakraLink href="#">
                                    <InstagramIconSmall />
                                </ChakraLink>
                                <ChakraLink href="#">
                                    <TwitterIconSmall />
                                </ChakraLink>
                            </Flex>
                            <Flex justifyContent="flex-start" alignItems="center">
                                <PhoneIconSmall />
                                <Text>1.800.909.2711</Text>
                            </Flex>
                        </Flex>
                    </AngledInfoFlex>
                    <Flex justifyContent="flex-start" h="50px" mr="50px">
                        <NavLink to="/services">SERVICES</NavLink>
                        <NavLink to="/claim">SCHEDULE CLAIM</NavLink>
                        <NavLink to="/contact">CONTACT US</NavLink>
                    </Flex>
                </Box>
            </Flex>
            <Box display={['block', 'block', 'none']}>
                <FocusLock disabled={!open}>
                    <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
                    <Menu open={open} setOpen={setOpen} id={menuId} />
                </FocusLock>
            </Box>
        </>
    );
};

export default Header;
