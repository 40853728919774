import React from 'react';
import { bool, func } from 'prop-types';
import styled from '@emotion/styled';

export const StyledBurger = styled.button(
    ({ theme, open }) => `
        position: absolute;
        top: 5%;
        right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 10;

        span {
            width: 2rem;
            height: 0.25rem;
            background: ${open ? theme.color.black : theme.color.black};
            border-radius: 10px;
            transition: all 0.3s linear;
            position: relative;
            transform-origin: 1px;
            display: block;
            margin-bottom: 8px;
        
        :first-of-type {
            transform: ${open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        
        :nth-of-type(2) {
            opacity: ${open ? '0' : '1'};
            transform: ${open ? 'translateX(20px)' : 'translateX(0)'};
        }
        
        :nth-of-type(3) {
            transform: ${open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`
);

const Burger = ({ open, setOpen, ...props }) => {
    const isExpanded = open ? true : false;

    return (
        <StyledBurger
            aria-label="Toggle menu"
            aria-expanded={isExpanded}
            open={open}
            onClick={() => setOpen(!open)}
            {...props}>
            <span />
            <span />
            <span />
        </StyledBurger>
    );
};

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};

export default Burger;
