import React from 'react';
import { Link } from 'gatsby';
import { bool } from 'prop-types';
import styled from '@emotion/styled';

export const StyledMenu = styled.nav(
    ({ theme, open }) => `
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: ${theme.color.gray[100]};
        transform: ${open ? 'translateX(0)' : 'translateX(-100%)'};
        height: 100vh;
        text-align: left;
        padding: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.3s ease-in-out;
        z-index: 9;
        
        @media (max-width: ${theme.breakpoints[0]}) {
            width: 100%;
        }
        
        a {
            font-size: 1.5rem;
            text-transform: uppercase;
            padding: 1rem 0;
            font-weight: normal;
            letter-spacing: 0.2rem;
            color: black;
            text-decoration: none;
            transition: color 0.3s linear;
        
            @media (max-width: ${theme.breakpoints['768px']}) {
              font-size: 1.5rem;
              text-align: center;
            }
            
            &:hover {
              color: ${theme.color.brand};
            }
        }
    `
);

const Menu = ({ open, ...props }) => {
    const isHidden = open ? true : false;
    const tabIndex = isHidden ? 0 : -1;

    return (
        <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
            <Link to="/services" tabIndex={tabIndex}>
                Home
            </Link>
            <Link to="/services" tabIndex={tabIndex}>
                Services
            </Link>
            <Link to="/claim" tabIndex={tabIndex}>
                Schedule Claim
            </Link>
            <Link to="/contact" tabIndex={tabIndex}>
                Contact
            </Link>
        </StyledMenu>
    );
};

Menu.propTypes = {
    open: bool.isRequired,
};

export default Menu;
